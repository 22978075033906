

//const axios = require('axios');


const createPaymentIntent = options => {
  return window
    .fetch(`/create-payment-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      return res.json();
      /*
      if (res.status === 200) {
        console.log({'apipi':res});
        return res.json();
      } else {
        return res.status;
      }
      */
    })
    .then(data => {
     // return data;
      
      if (!data || data.error) {
        console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      } else {
       // console.log({'data':data})
        return data;
      }
      
    });
};

const getProductDetails = (orderid) => {
  return window
    .fetch(`/order/:orderid`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
  .then(res => {
    if (res.status === 200) {
      //console.log(res.status);
      //return res.json();
    } else {
      return null;
    }
  })
  .then(data => {
    if (!data || data.error) {
      console.log("API error:", { data });
      throw Error("API Error");
    } else {
      return data;
    }
  });
};


const getPublicStripeKey = options => {
  return window
    .fetch(`/public-key`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => {
      if (res.status === 200) {
        //console.log({'pkdel': res})
        return res.json();
      } else {
        //console.log({'pkfil': res})
        return res;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data.publicKey;
      }
    });
};


const api = {
  createPaymentIntent,
  getPublicStripeKey: getPublicStripeKey,
  getProductDetails: getProductDetails
};

export default api;
