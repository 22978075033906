// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pasha styles – Brand-overrides, can split these out */
:root {
  --accent-color: #ed5f74;
  --success-color: #5fed82;
  --headline-color: var(--accent-color);
  --logo-image: url("https://storage.googleapis.com/stripe-sample-images/logo-pasha.svg");
}

.pasha-image-stack {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto;
}

.pasha-image-stack img {
  border-radius: var(--radius);
  background-color: var(--gray-border);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  transition: all 0.8s ease;
  opacity: 0;
}

.pasha-image-stack img:nth-child(1) {
  transform: translate(12px, -12px);
  opacity: 1;
}
.pasha-image-stack img:nth-child(2) {
  transform: translate(-24px, 16px);
  opacity: 1;
}
.pasha-image-stack img:nth-child(3) {
  transform: translate(68px, -100px);
  opacity: 1;
}

.sample-info {
  padding: 20px 30px;
  border: 1px solid var(--gray-border);
  border-radius: var(--radius);

  position: fixed;
  top: 10px;
  left: 50%;
  margin-left: -250px;
  max-width: 500px;
}

@media (max-width: 720px) {
  .sample-info {
    top: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,wDAAwD;AACxD;EACE,uBAAuB;EACvB,wBAAwB;EACxB,qCAAqC;EACrC,uFAAuF;AACzF;;AAEA;EACE,aAAa;EACb,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE,4BAA4B;EAC5B,oCAAoC;EACpC;mCACiC;EACjC,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,iCAAiC;EACjC,UAAU;AACZ;AACA;EACE,iCAAiC;EACjC,UAAU;AACZ;AACA;EACE,kCAAkC;EAClC,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,oCAAoC;EACpC,4BAA4B;;EAE5B,eAAe;EACf,SAAS;EACT,SAAS;EACT,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,MAAM;EACR;AACF","sourcesContent":["/* Pasha styles – Brand-overrides, can split these out */\n:root {\n  --accent-color: #ed5f74;\n  --success-color: #5fed82;\n  --headline-color: var(--accent-color);\n  --logo-image: url(\"https://storage.googleapis.com/stripe-sample-images/logo-pasha.svg\");\n}\n\n.pasha-image-stack {\n  display: grid;\n  grid-gap: 12px;\n  grid-template-columns: auto auto;\n}\n\n.pasha-image-stack img {\n  border-radius: var(--radius);\n  background-color: var(--gray-border);\n  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),\n    0 3px 6px 0 rgba(0, 0, 0, 0.07);\n  transition: all 0.8s ease;\n  opacity: 0;\n}\n\n.pasha-image-stack img:nth-child(1) {\n  transform: translate(12px, -12px);\n  opacity: 1;\n}\n.pasha-image-stack img:nth-child(2) {\n  transform: translate(-24px, 16px);\n  opacity: 1;\n}\n.pasha-image-stack img:nth-child(3) {\n  transform: translate(68px, -100px);\n  opacity: 1;\n}\n\n.sample-info {\n  padding: 20px 30px;\n  border: 1px solid var(--gray-border);\n  border-radius: var(--radius);\n\n  position: fixed;\n  top: 10px;\n  left: 50%;\n  margin-left: -250px;\n  max-width: 500px;\n}\n\n@media (max-width: 720px) {\n  .sample-info {\n    top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
