// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
  max-width: 825px;
  margin: 0 auto;
  padding: 0 22px;
  font-size: 14px;
  background: white;
  color: #6a7c94;
  border-radius: 22px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DemoText.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;EACnB,2CAA2C;EAC3C,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;AACf","sourcesContent":[".banner {\n  max-width: 825px;\n  margin: 0 auto;\n  padding: 0 22px;\n  font-size: 14px;\n  background: white;\n  color: #6a7c94;\n  border-radius: 22px;\n  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);\n  display: flex;\n  align-items: center;\n  box-sizing: border-box;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
