import React from "react";
import "./DemoText.css";

export default function DemoText() {
  return (
    <div className="banner">
      <span>
        This is the{" "}
        <a href="https://www.portlandpress.com">Portland Press </a>
	 pay site.     
 </span>
    </div>
  );
}
