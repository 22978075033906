import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import api from "../api";
//import help from '../help';
import axios from 'axios';
import useRouter from '../help';



export default function CheckoutForm() {
  const router = useRouter();
 // const [data, setData] = useState({hits: {}});
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  //let { orderid } = useParams()

 // console.log(router.query.orderid);
  const orderid = router.query.orderid;
  const baseurl = '/product-details/';
  const orderurl = `${baseurl}${orderid}`;
  //console.log([baseurl,orderurl]);

  
  useEffect(() => {
    // Step 1: Fetch product details such as amount and currency from
    // API to make sure it can't be tampered with in the client.
    const fetchData = (async () => {
      try {
        const result = await axios(
          orderurl,
        );
       // console.log(result.data[0]);
       // setData(result.data[0]);
        //let tr = result;
        //console.log(tr);
        return result;
        } catch (error) {
        //console.log(error);
      }
      
    })
    
    
    
      fetchData().then(productDetails => {
       // console.log({'proddeet':productDetails});
        let te = (productDetails.data.currency).toLowerCase();
        let re = productDetails.data.amount;
        //console.log(te);
        //console.log(re);
        setAmount(re);
        setCurrency(te);
        //console.log({amount});
       // console.log(currency.toLowerCase());
        const resty = productDetails.data;
	//console.log({'resty':resty});
        return resty;
    },
    [orderurl]).then(resty => {
    const ue = parseFloat(resty.amount * 100);
    const rw = resty.currency.toLowerCase();
    const ord = resty.ordid;
    const cust = resty.custid;
    const seq = resty.seq;
    //console.log({'a':ue,'b':rw});
   
  
    const fetchSecret = (async () => {
      try{
        const sec1 = await api.createPaymentIntent({
          payment_method_types: ["card"],
          amount: ue,
          currency: rw,
	  orderid: ord,
  	  custid: cust,
          seq: seq
         
        });
       // console.log({'checksec':sec1})
        return sec1
      } catch (err){
          console.log({'sec1err': err})
      }
    })
    return fetchSecret();  
     })
     .then(data => {
      //console.log({'ore':data});
        setClientSecret(data.clientSecret);
       // console.log(data.clientSecret);
      })
      .catch(err => {
        setError(err.message);
        //console.log({'secret':err.message})
      });
  }, [amount,currency,orderurl]);

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value
        }
      }
    });

    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
      console.log("[error]", payload.error);
    } else {
      setError(null);
      setSucceeded(true);
      setProcessing(false);
      setMetadata(payload.paymentIntent);
      console.log("[PaymentIntent]", payload.paymentIntent);
    }
  };

  const renderSuccess = () => {
    return (
      <div className="sr-field-success message">
        <h1>Your payment succeeded</h1>
	    {/*<p>View PaymentIntent response:</p>
	   <pre className="sr-callout">
          //<code>{JSON.stringify(metadata, null, 2)}</code>
        //</pre>*/}
      </div>
    );
  };

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
    hidePostalCode:true
    };

    return (
      <form onSubmit={handleSubmit}>
        <h1>
          {currency.toLocaleUpperCase()}{" "}
          {amount.toLocaleString(navigator.language, {
            minimumFractionDigits: 2
          })}{" "}
        </h1>
      
        <h4>Complete your order</h4>

        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              autoComplete="cardholder"
              className="sr-input"
            />
          </div>

          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options}
            />
          </div>
        </div>

      {error && <div className="message sr-field-error">{error}</div>}

        <button
          className="btn"
          disabled={processing || !clientSecret || !stripe}
        >
          {processing ? "Processing…" : "Pay"}
        </button>
      </form>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
}
