import React from "react";
//import { loadStripe } from "@stripe/stripe-js";
//import { Elements } from "@stripe/react-stripe-js";
//import DemoText from "./components/DemoText";
//import CheckoutForm from "./components/CheckoutForm";
import Base from './components/checks';
//import Appi from './api2';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  
} from "react-router-dom";

//import api from "./api";

//import "./App.css";

//const stripePromise = api.getPublicStripeKey().then(key => loadStripe(key));

export default function App() {
  
  return (
  <Router>  
    <Switch>
     
      <Route exact path="/" component={Base} />
      <Route path="/order/"  component={Base} />
      <Route exact path="/:orderid"  component={Base} />
  
    </Switch>
  </Router>
  );
  
}
