// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sr-combo-inputs {
  margin: 20px 0;
}

.sr-input {
  font-size: 16px;
}

.sr-card-element {
  padding-top: 12px;
}

.btn {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CheckoutForm.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".sr-combo-inputs {\n  margin: 20px 0;\n}\n\n.sr-input {\n  font-size: 16px;\n}\n\n.sr-card-element {\n  padding-top: 12px;\n}\n\n.btn {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
